<template>
  <div>
    <Header
      class="flex"
      :back="true"
      :text="backText"
      :route="routeBack"
      :title="$t('Views.Book.filters')"
    >
      <h5 class="header-title unselectable">
        {{ $t('Views.Book.filters') }}
      </h5>
    </Header>
    <div class="scrollable">
      <Card
        v-if="loaded && (equipmentsBoolean.length || equipmentsValue.length)"
      >
        <div v-if="equipmentsValue.length">
          <FilterIncrement
            v-for="(equipment,index) in equipmentsValue"
            :key="index"
            class="margin margin-medium margin-bottom marign-top"
            :equipment="equipment"
            @incrementChanged="updateIncrement"
            @click="filterClicked(equipment.Title)"
          />
        </div>
        <div
          v-if="equipmentsBoolean.length"
          class="filter-selecatable_container"
        >
          <FilterSelectable
            v-for="(equipment,index) in equipmentsBoolean"
            :key="index"
            class="margin margin-top margin-medium-large"
            :equipment="equipment"
            :selected="selectedBooleans.includes(equipment.Title)"
            @click="filterClicked(equipment.Title)"
          />
        </div>
      </Card>
      <Card>
        <Timepicker
          :start-date="fromDate"
          :end-date="untilDate"
        />
      </Card>
      <Card position="bottom">
        <div class="filter-apply-buttons_container">
          <Button
            variant="primary-reverse"
            @click="clearAllFilters()"
          >
            {{ $t('Views.Book.clear_all') }}
          </Button>
          <Button
            variant="primary"
            @click="$router.push(routeBack)"
          >
            {{ $t('Views.Book.apply_filter') }}
          </Button>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-expressions */
import WeekState from '@/singletons/week.state.singleton';
import Card from '@/components/card/card.vue';
import Header from '@/components/headers/header.vue';
import QueryMixin from '@/mixins/query.mixin';
import ZoneState from '@/singletons/zone.state.singleton';

import Button from '@/components/button/button.vue';

import TimepickerController from '@/components/timepicker/timepicker.controller';
import {
  getHasFilters, getAmountFilters, createHasString, createAmountString, createQueryStringFromObject,
} from '@/functions/filter.query.functions';
import { removeDuplicateValueInObjectArray } from '@/functions/helper.functions';
import Timepicker from './components/filter.timepicker.vue';
import FilterSelectable from './components/filter.selectable.vue';
import FilterIncrement from './components/filter.increment.vue';
import ExtendedDate from '../../classes/extended.date.class';

export default {
  components: {
    FilterSelectable,
    FilterIncrement,
    Timepicker,
    Button,
    Card,
    Header,
  },
  mixins: [QueryMixin],
  data() {
    return {
      type: '',
      loaded: false,
      WeekState,
      TimepickerController,
      selectedBooleans: [],
      selectedValues: {},
    };
  },
  computed: {
    fromDate() {
      return new ExtendedDate();
    },
    untilDate() {
      return null;
    },
    equipmentsValue() {
      const arr = ZoneState.resourcesOfActiveType.map(({ Equipment }) => Equipment);
      const eq = [].concat(...arr);
      const eqStrings = eq.filter((item) => typeof item.Value === 'string');
      const eqStringNoDuplicates = eqStrings.filter((e) => {
        const duplicates = eqStrings.filter(({ Title }) => e.Title === Title);
        // If we have duplicates return the one with the highest value
        if (duplicates.length > 0) {
          const values = duplicates.map(({ Value }) => Number(Value));
          const max = Math.max(...values);
          return (Number(e.Value) === max);
        }
        return true;
      });
      return removeDuplicateValueInObjectArray(eqStringNoDuplicates, 'Title');
      // return eqStringNoDuplicates;
    },
    equipmentsBoolean() {
      const arr = ZoneState.resourcesOfActiveType.map(({ Equipment }) => Equipment);
      const eq = [].concat(...arr);
      const eqStrings = eq.filter((item) => typeof item.Value === 'boolean');
      const noDuplicatesEqStrings = removeDuplicateValueInObjectArray(eqStrings, 'Title');
      return noDuplicatesEqStrings;
    },
    routeBack() {
      const queryString = createQueryStringFromObject(this.$route.query);
      return `/book/${this.type}?${queryString}`;
    },
    backText() {
      return ZoneState.highlightedResources[0]?.TypeName;
    },
  },
  async mounted() {
    this.type = this.$route.params.type;
    const { has, amount } = this.$route.query;
    if (has) { this.selectedBooleans = getHasFilters(has); }
    if (amount) { Object.assign(this.selectedValues, getAmountFilters(amount)); }
    this.loaded = true;
  },
  methods: {
    clearAllFilters() {
      this.removeQuery('has');
      this.selectedBooleans = [];
      this.removeQuery('amount');
      this.selectedValues = {};
      this.removeQuery('from');
      this.removeQuery('until');
    },
    updateIncrement(title, value) {
      value === 0 ? delete this.selectedValues[title] : this.selectedValues[title] = value;
      (Object.entries(this.selectedValues).length > 0)
        ? this.replaceQuery({ amount: createAmountString(this.selectedValues) })
        : this.removeQuery('amount');
    },
    filterClicked(title) {
      // eslint-disable-next-line no-unused-expressions
      if (this.selectedBooleans.includes(title)) {
        const index = this.selectedBooleans.indexOf(title);
        this.selectedBooleans.splice(index, 1);
      } else {
        this.selectedBooleans.push(title);
      }
      ZoneState.activeHasEquipmentFilters = this.selectedBooleans;
      (this.selectedBooleans.length > 0)
        ? this.replaceQuery({ has: createHasString(this.selectedBooleans) })
        : this.removeQuery('has');
    },
  },
};
</script>

<style lang="scss" >
.filter-selecatable_container{
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.filter-apply-buttons_container{
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

</style>
