<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for -->
  <div v-if="TimepickerController.hasLoadedOnce">
    <TimepickerHeader
      class="header"
      :start-time="TimepickerController.headerStartTime()"
      :end-time="TimepickerController.headerEndTime()"
    />

    <div class="checkboxtext-container margin margin-top margin-bottom margin-medium">
      <CheckBoxText
        v-for="(obj, index) in getCheckBoxAlternative()"
        v-if="shouldShowTimeFilter(obj.until)"
        :key="index"
        :text="obj.text"
        :active="index === selected"
        @click="setSelectedCheckBox(index)"
      />
    </div>

    <div>
      <AccordionButton
        v-if="TimepickerController.canShowEarlierTimes()"
        class="accbutton top"
        :open="false"
        :arrow-direction="'up'"
        :closed-title="$t('Components.AccordionButton.show_earlier_time')"
        @clicked="accordionEarlierClicked"
      />

      <TimepickerTimeslots
        v-if="TimepickerController.hasLoadedOnce"
        :timepicker-controller="TimepickerController"
      />

      <AccordionButton
        v-if="TimepickerController.canDisplayMoreItems()"
        class="accbutton bottom"
        :open="false"
        :closed-title="$t('Components.AccordionButton.show_later_time')"
        @clicked="accordionLaterClicked"
      />
    </div>
  </div>
</template>

<script>

import ExtendedDate from '@/classes/extended.date.class';
import AccordionButton from '@/components/button/accordion.button.vue';
import TimepickerHeader from '@/components/timepicker/timepicker.header.vue';
import TimepickerTimeslots from '@/components/timepicker/timepicker.timeslots.vue';
import TimepickerController from '@/components/timepicker/timepicker.controller';
import CheckBoxText from '@/components/checkbox/checkbox.text.vue';
import WeekState from '@/singletons/week.state.singleton';
import QueryMixin from '../../../mixins/query.mixin';

export default {
  components: {
    AccordionButton,
    TimepickerHeader,
    TimepickerTimeslots,
    CheckBoxText,
  },
  mixins: [QueryMixin],
  data() {
    return {
      startDate: new ExtendedDate().setHours(0, 0, 0, 0),
      TimepickerController,
      selected: null,
      controllerReady: false,
      date: null,
    };
  },
  computed: {
    from() {
      return TimepickerController.startTimestamp();
    },
    until() {
      return TimepickerController.endTimstamp();
    },
    shouldShowTimeFilter() {
      console.log(new ExtendedDate(this.date).isToday());
      return (endUnixStamp) => {
        if (!new ExtendedDate(this.date).isToday()) return true;
        return (new ExtendedDate().setHours(0, 0, 0, 0) + endUnixStamp > new ExtendedDate().getTime());
      };
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.query': function () {
      const { from, until } = this.$route.query;
      if (!from) {
        TimepickerController.resetSlots();
        this.selected = null;
      }
      if (!until) {
        TimepickerController.resetSlots();
        this.selected = null;
      }
    },
    from() {
      if (this.controllerReady) this.replaceQuery({ from: this.from });
    },
    until() {
      if (this.controllerReady) this.replaceQuery({ until: this.until });
    },
  },
  async created() {
    const { date } = WeekState;
    const { from, until } = this.$route.query;
    this.date = date;
    const disablePassedTimeslots = !(new ExtendedDate(date).isToday());
    await TimepickerController.init(new ExtendedDate(), null, null, 16, disablePassedTimeslots);
    if (Number(from) && Number(until)) {
      TimepickerController.setStartSlotWithTimestamp(Number(from));
      TimepickerController.setEndSlotWithTimestamp(Number(until));
    } else {
      this.replaceQuery({ from: 0, until: 0 });
    }
    this.controllerReady = true;
  },
  methods: {
    setSelectedCheckBox(index) {
      TimepickerController.resetSlots();
      this.selected = this.selected === index ? null : index;
      if (this.selected === null) {
        this.replaceQuery({ from: 0, until: 0 });
        return;
      }
      const alts = this.getCheckBoxAlternative();
      const { from, until } = alts[this.selected];
      let fromTime = from;
      const isToday = new ExtendedDate(this.date).isToday();
      // If today from time should be milliseconds from midnight if selected from is less than now
      if (isToday && ((new ExtendedDate().setHours(0, 0, 0, 0) + from) < new ExtendedDate().getTime())) {
        const today = new ExtendedDate();
        const msSinceMidnight = today.getTime() - today.setHours(0, 0, 0, 0);
        fromTime = msSinceMidnight;
      }
      this.replaceQuery({ from: fromTime, until });
      TimepickerController.setStartSlotWithTimestamp(fromTime);
      TimepickerController.setEndSlotWithTimestamp(until);
    },
    getCheckBoxAlternative() {
      return [
        { text: this.$t('Views.Book.whole_day'), from: 28800000, until: 61200000 },
        { text: this.$t('Views.Book.morning'), from: 28800000, until: 43200000 },
        { text: this.$t('Views.Book.afternoon'), from: 43200000, until: 61200000 },
      ];
    },
    accordionEarlierClicked(value) { TimepickerController.toggleEarlierTimeslots(value); },
    accordionLaterClicked(value) { TimepickerController.toggleLaterTimeslots(value); },
  },
};

</script>

<style lang="scss" scoped>

  .header {
    margin-bottom: 2rem;
  }

  .checkboxtext-container{
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    margin-bottom: 3rem;
  }

  .accbutton {
    margin: 0 0 0 auto;

    &.top {
      margin-bottom: 1.5rem;
    }

    &.bottom {
      margin-top: 1.5rem;
    }
  }

</style>
