<template>
  <div
    class="filter_increment-wrapper-container"
  >
    <div class="filter_increment-information">
      <svg-icon
        class="filter-icon"
        :src="`/icons/eq-icons/${equipment.Icon}.svg`"
      />
      <h6 class="normal-medium secondary unselectable">
        {{ equipment.Title }}
      </h6>
    </div>
    <div class="filter_increment-wrappers">
      <div
        class="filter_increment-wrapper"
        :class="{'isDisabled': isMin}"
        @click="min()"
      >
        <svg-icon
          class="increment-icon"
          src="/icons/minus.svg"
        />
      </div>
      <p class="secondary unselectable">
        {{ currValue }}
      </p>
      <div
        class="filter_increment-wrapper "
        :class="{'isDisabled': isMax}"
        @click="add()"
      >
        <svg-icon
          class="increment-icon"
          src="/icons/plus.svg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAmountFilters,
} from '@/functions/filter.query.functions';

export default {
  props: {
    equipment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currValue: 0,
    };
  },
  computed: {
    isMin() { return this.currValue === 0; },
    isMax() { return this.currValue === Number(this.equipment.Value); },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.query.amount': function () {
      this.setCurrentValueFromQuery();
    },
  },
  mounted() {
    this.setCurrentValueFromQuery();
  },
  methods: {
    setCurrentValueFromQuery() {
      if (this.$route.query?.amount) {
        const activeFilters = getAmountFilters(this.$route.query.amount);
        const thisFilter = activeFilters[this.equipment.Title];
        if (thisFilter) {
          this.currValue = thisFilter;
          return;
        }
      }
      this.currValue = 0;
    },
    add() {
      if (this.isMax) return;
      let value = this.currValue;
      value += 1;
      this.$emit('incrementChanged', this.equipment.Title, value);
    },
    min() {
      if (this.isMin) return;
      let value = this.currValue;
      value -= 1;
      this.$emit('incrementChanged', this.equipment.Title, value);
    },
  },
};
</script>

<style lang="scss">
.filter_increment-wrapper-container{
    display: flex;
    justify-content: space-between;
  .filter_increment-information{
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    h6{
      margin: auto;
    }
    .filter-icon{
       margin: auto;
      svg{
        width: 1.8rem;
        height: 1.8rem;
        fill: var(--secondary-color);
        color: var(--secondary-color)
      }
    }
  }
  .filter_increment-wrappers{
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    p{
      font-variant-numeric: tabular-nums;
      margin: auto;
    }
    .filter_increment-wrapper {
      color: var(--primary-font-color);
      border: 1px solid var(--border-color);
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      display: flex;
      .increment-icon {
       display: flex;
       width: 100%;
        svg{
          margin: auto;
          fill: var(--secondary-color);
          color: var(--secondary-color);
        }
      }

      &:hover {
        cursor: pointer;
      }
      &.isDisabled {
        opacity: 0.4;
        cursor: default;
      }
    }
  }
}

</style>
