<template>
  <div
    class="fliter-selactable_container"
    :class="selected ? 'fill-selectable' : ''"
    @click="$emit('click')"
  >
    <svg-icon
      class="filter-icon"
      :src="`/icons/eq-icons/${equipment.Icon}.svg`"
    />
    <p class="small-semi secondary unselectable">
      {{ equipment.Title }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    equipment: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
  },

};
</script>

<style lang="scss">
.fliter-selactable_container{
    padding: 0.8rem 0.5rem;
    flex-basis: 30%;
  &.fill-selectable{
    border-color: var(--primary-color-light);
    background-color: var(--primary-color-light);
    p{
      color: var(--primary-color);
    }
    .filter-icon{
      svg{
        fill: var(--primary-color);
        color: var(--primary-color);
      }
    }
  }
  &:hover{
    cursor: pointer;
  }
  p{
    margin: 0;
    text-align: center;
    overflow-wrap: normal;
    color: var(--secondary-color);
  }
  flex-direction: column;
  display: flex;
  border: 1px solid var(--border-color);
  border-radius: 10px;
 .filter-icon{
   margin: auto;
   svg{
        fill: var(--secondary-color);
        color: var(--secondary-color);
      }
  }
}

</style>
