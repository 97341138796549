<template>
  <div
    class="container"
    :class="active ? 'active' : ''"
    @click="$emit('click')"
  >
    <p class="normal-medium unselectable">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.container{
  border: 1px solid var(--primary-color);
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: flex;
  &:hover{
    cursor: pointer;
  }
  &.active{
    background-color: var(--primary-color-light);
    border: 1px solid var(--primary-color-light);
  }
  p{
    color:  var(--primary-color);
    margin: auto;
  }
}
</style>
